var appConfig = {
    //backendUrl: 'http://localhost:8082/dlv-elektronski-testovi/api/',
    backendUrl: 'http://services.btb4net.com/dlv-elektronski-testovi/api/',
    //backendUrl: 'http://services.dlv.org.rs/api/',
   init: function(){

   }
}

appConfig.init();

export default appConfig;